import { combineReducers } from 'redux';
import { router5Reducer } from 'redux-router5';
import projectsReducer from './projects';

export default function createReducer() {
  return combineReducers({
    router: router5Reducer,
    projects: projectsReducer,
  });
}

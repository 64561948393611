/* eslint-disable react/no-array-index-key */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import memoize from 'memoize-one';

import Image from '../../components/Image';

import projectShape from '../../redux/reducers/projects/shape';

import './Project.css';

class Project extends PureComponent {
  static propTypes = {
    projects: PropTypes.arrayOf(projectShape).isRequired,
    route: PropTypes.object.isRequired,
  };

  getProject = memoize((projects, uri) => {
    const project = projects.find(p => p.uri === uri);
    if (project) {
      return project;
    }

    return false;
  });

  render() {
    const { projects, route } = this.props;
    const project = this.getProject(projects, route.params.uri);
    if (!project) {
      return null;
    }
    return (
      <div>
        <div
          className="text-center"
        >
          <div className="text-big">
            {project.title}
          </div>

          <div className="text-medium">
            {project.subtitle.split('\n').map((line, i) => (
              <div key={i}>
                {line}
              </div>
            ))}
          </div>
        </div>

        <img
          className="project-image"
          src={project.image}
          alt={project.title}
        />

        <div className="project-details">
          {Object.keys(project.details).map(key => (
            <div key={key}>
              <strong>{`${key}: `}</strong>
              {project.details[key]}
            </div>
          ))}
        </div>

        <div className="project-sections">
          {project.sections.map((section, i) => (
            <div
              key={i}
              className="project-section"
              style={{
                gridTemplateColumns: `repeat(${Object.keys(section).length}, 1fr)`,
              }}
            >
              { Object.keys((section)).map((key) => {
                if (key === 'html') {
                  return (
                    <div
                      key={key}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: section[key] }}
                    />
                  );
                }

                if (key.startsWith('image')) {
                  return (
                    <Image
                      className={`project-section-image${key === 'imageBig' ? '-big' : ''}`}
                      key={key}
                      src={section[key]}
                      alt={project.title}
                    />
                  );
                }

                if (key === 'text') {
                  return (
                    <p key={key}>
                      {section[key]}
                    </p>
                  );
                }

                if (key === 'link') {
                  const link = section[key];
                  return (
                    <a
                      key={key}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="project-section-image-big"
                        alt={project.title}
                        src={link.image}
                      />
                    </a>
                  );
                }

                return null;
              })}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default connect(state => ({ projects: state.projects }))(Project);

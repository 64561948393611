import { router5Middleware } from 'redux-router5';
import { TRANSITION_SUCCESS } from 'redux-router5/lib/actionTypes';
import logger from 'redux-logger';

const scrollToTop = () => next => (action) => {
  // check for noScroll in action payload
  let scroll = true;

  const { payload } = action;
  if (
    payload
    && payload.route
    && payload.route.meta
    && payload.route.meta.options
    && payload.route.meta.options.noScroll
  ) {
    scroll = false;
  }

  if (scroll && action.type === TRANSITION_SUCCESS) {
    window.scrollTo(0, 0);
  }

  return next(action);
};

export default function configureMiddleware(router) {
  const middleware = [router5Middleware(router), scrollToTop];

  if (process.env.NODE_ENV !== 'production') {
    middleware.push(logger);
  }

  return middleware;
}

import React from 'react';

import { Link } from 'react-router5';

import './Header.css';

const Header = () => (
  <div className="header">
    <Link
      className="title text-medium active"
      style={{ marginRight: 'auto' }}
      routeName="main"
    >
      Ragnhild Hjalmarsdóttir Højgaard
    </Link>

    <div>
      <Link
        routeName="main"
      >
        Work
      </Link>

      <Link
        routeName="news"
      >
        News
      </Link>

      <Link
        routeName="about"
      >
        About
      </Link>
    </div>
  </div>
);


export default Header;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createRouteNodeSelector } from 'redux-router5';
import memoize from 'memoize-one';
import { Link } from 'react-router5';

import Header from './Header';

import Main from '../Main';
import About from '../About';
import Project from '../Project';
import Footer from './Footer';

import './App.css';
import News from '../News';

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
  static propTypes = {
    route: PropTypes.object.isRequired,
  };

  getChild = memoize((route) => {
    switch (route.name) {
      case 'main':
        return <Main />;
      case 'news':
        return <News />;
      case 'about':
        return <About />;
      case 'project':
        return <Project route={route} />;
      default:
        return null;
    }
  });

  render() {
    const { route } = this.props;
    return (
      <div className="container">
        <Header />

        <div className="page">
          { route.name !== 'main' && (
            <div style={{ marginBottom: '1.5rem' }}>
              <Link routeName="main">
                &lt; Back
              </Link>
            </div>
          )}

          { this.getChild(route) }
        </div>

        <Footer />
      </div>
    );
  }
}

export default connect(createRouteNodeSelector(''))(App);

import React from 'react';
import { Link } from 'react-router5';

import projectShape from '../../redux/reducers/projects/shape';

import './ProjectPreview.css';

const ProjectPreview = ({ project }) => (
  <Link
    className="project-preview"
    routeName="project"
    routeParams={{ uri: project.uri }}
  >
    <div
      style={{
        background: `url(${project.image}) 50% 50% / cover no-repeat`,
      }}
      className="project-preview-image"
    >
      <div className="overlay" />
      <div className="project-title">
        { project.title }
      </div>
    </div>
  </Link>
);

ProjectPreview.propTypes = {
  project: projectShape.isRequired,
};

export default ProjectPreview;

import React from 'react';
import PropTypes from 'prop-types';

const NewsItem = (props) => {
  const {
    image,
    link,
    title,
    blurb,
    details,
  } = props;
  return (
    <div className="news-link">
      { image ? (
        <a href={link}>
          <img src={image} alt={title} />
        </a>
      ) : (
        <span />
      ) }

      <a className="news-link-text" href={link}>
        <div className="text-medium">
          { title }
        </div>

        { blurb && <q className="news-link-blurb">{ blurb }</q> }

        { details && <div>{ details }</div> }
      </a>
    </div>
  );
};

NewsItem.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  blurb: PropTypes.string,
  details: PropTypes.string,
};

export default NewsItem;

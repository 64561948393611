import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazy-load';

class Image extends PureComponent {
  static propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = { alt: '', className: '' };

  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  setVisible = () => this.setState({ visible: true });

  render() {
    const {
      src,
      alt,
      className,
    } = this.props;

    return (
      <LazyLoad
        offsetVertical={300}
        height={this.state.visible ? undefined : 500}
        onContentVisible={this.setVisible}
        debounce={false}
      >
        <img
          src={src}
          alt={alt}
          className={className}
        />
      </LazyLoad>
    );
  }
}


export default Image;

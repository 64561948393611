import PropTypes from 'prop-types';

const projectShape = PropTypes.shape({
  uri: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  details: PropTypes.object,
  sections: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    image: PropTypes.string,
    video: PropTypes.string,
  })),
});

export default projectShape;

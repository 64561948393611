import React from 'react';

import './Footer.css';

const Footer = () => (
  <div className="footer">
    <a href="tel:+4531522244">
      +45 31522244
    </a>

    <a
      href="mailto:ragnhild@hjalmarsdottir.com"
    >
      ragnhild@hjalmarsdottir.com
    </a>

    <a
      href="https://www.linkedin.com/in/ragnhild-hjalmarsd%C3%B3ttir-h%C3%B8jgaard-2762a388"
      target="_blank"
      rel="noopener noreferrer"
    >
      LinkedIn
    </a>

    <a
      href="http://instagram.com/pippan"
      target="_blank"
      rel="noopener noreferrer"
    >
      Instagram
    </a>

    <div
      style={{ paddingTop: '0.5rem' }}
      className="text-small"
    >
      © 2018. Ragnhild Hjalmarsdóttir Højgaard, CoolBoy Industries
    </div>
  </div>
);

export default Footer;

import React from 'react';

const infoBox = {
  display: 'grid',
};

const About = () => (
  <div className="text-with-image">
    <img
      alt="ragnhild"
      src="/ragnhild.jpg"
    />

    <div style={infoBox}>
      <div>
        <p>
          Ragnhild Hjalmarsdottir Højgaard works in the intersection between art and design. Ragnhild is originally from the Faroe Islands, and is currently based in Copenhagen.
        </p>

        <p>
          Her work evolves around concept development and surface design in a broad sense. Both on a larger architectual scale, within interior design and creating artwork.
        </p>

        <p>
          The past few years her work has focused on researching the potential of the faroese wool. Based on the qualities of the material, she develops surfaces through experimenting in dialouge between material and craft.
        </p>
      </div>
    </div>
  </div>
);

export default About;

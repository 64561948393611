import React from 'react';

import NewsItem from './NewsItem';

import './News.css';

const News = () => (
  <div>
    <div className="text-center text-big">
      News
    </div>

    <NewsItem
      title="Trends and Tradition"
      link="https://www.trendstraditions.dk/"
      blurb="For the first time the, the collaboration with Kasper Thorup will be exhibited; the awardwinning Noel Chair with a new version the classic flagline made from faroese wool. On April 30th 2020"
    />

    <NewsItem
      title="NORDIC TEXTILE ART"
      link="https://www.nordictextileart.net/nordic-textile-meeting-in-denmark-2020-across-generations/"
      blurb="NTA MEETING 2020 - ACROSS GENERATIONS: On saturday march 7th 2020. Ragnhild Hjalmarsdóttir will be having a lecture on her work on the artecoration in the two auditoriums of the Bjarke Ingels Group Building, Glasir Tórshavn College."
    />

    <NewsItem
      title="SUMMARLIST"
      link="https://www.nordlist.fo/summarlist-2019.html"
      blurb="Curated by Norðoyar Listafelag, “5 Colours of Nature” will be exhibited on Summarlist 2019."
    />

    <NewsItem
      title="ADORNO"
      link="https://adorno.design/editorial/novi-vixx-design-art-at-adorno/"
      blurb="Curated by Pil Brehdal, “5 Colours of Nature” will be exhibited on NOVI-VIXX hosted by ADORNO."
    />

    <NewsItem
      title="BINDIFESTIVAL"
      link="https://bindifestival.com/"
      blurb="The annual Knitting Festival in the Faroe Islands will be held 11. - 13. April 2019 in the town of Fuglafjørður. Ragnhild is exhibiting her Works ”5 Colours of Nature” along with two lectures on her work and research on the faroese wool. Find out more at the event website, bindifestival.com"
    />

    <NewsItem
      title="WALLPAPER"
      link="https://www.wallpaper.com/design/new-noma-restaurant-copenhagen-bjarke-ingels-design-2018?fbclid=IwAR0I9BqpPBhTG9PjDQzH0EYHcelTlqM4c1paxm0n_rg_TLVgg_CJJbdvuH8"
    />

    <NewsItem
      title="D&V um Glasir"
      link="https://kvf.fo/netvarp/sv/2018/10/19/20181019ullertydningarmikidtilfeingi"
    />

    <NewsItem
      title="Atlantic Review #3-2018"
      blurb="For tailor and designer Ragnhild Højgaard wool symbolises the proud history of sustainable sheep farming in the Faroe Islands."
      details="The article can be read from pages 8 to 14, with an English translation on page 16."
      image="/atlantic_review.png"
      link="https://issuu.com/atlanticairways/docs/2018_atlanticreview_edition3_skiggj/1?e=8236444/65308026"
    />

    <NewsItem
      title="Udsmykning af auditorier i BIG byggeriet ”Glasir”"
      blurb="Med inspiration fra de færøske klipper og grottevægge har Ragnhild Hjalmarsdóttir Højgaard skabt en serie gobeliner i færøsk fåreuld, der vil fungere som både udsmykning og akustikgardiner i to auditorier i det nye uddannelsescenter Glasir i Tórshavn, tegnet af Bjarke Ingels Group."
      image="/svfk_small.jpg"
      link="https://svfk.dk/project/udsmykning-auditorier-big-byggeriet-glasir"
    />

    <NewsItem
      title="Listaportal | Væl eydnað tekstilverk á Glasi"
      blurb="Í øllum tí slætta og blanka Glasirbygninginum er taktila, ullinta tilfarið hjá Ragnhild H.Højgaard eins og ein fjálg opinbering, ið tú sum áskoðari fært hug at balla teg í. (...) sera elegant gjørt"
      link="http://www.listaportal.com/tidindi/2018/10/8/vl-eydna-tekstilverk-glasi?fbclid=IwAR1jxkECp59LwylIFzbJC4FKN127HxHO3U39EfqLEsm7rovZXOpSPZ7T3dg"
    />

    <NewsItem
      title="Listaportal | Ólavsøkuframsýning við orðafrágreiðingum"
      blurb="Ragnhild Hjalmarsdóttir Højgaard hevur somuleiðis gjørt eitt áhugavert verk til Ólavsøkuframsýningina, sum er ein installatión, ein høgur rúmdeilari, ið er myndaður av tveimum vovnum standmyndum. (...) Tað er bæði stórbært og minimalistiskt, men við einum øðrvísi kroppsligum árini, ið kemst av, at tilfarið er føroysk ull við allari hennara søgu um lívbjargandi hita."
      link="http://www.listaportal.com/tidindi/2018/7/30/lavskuframsning-vi-orafrgreiingum?fbclid=IwAR1oD3pzjjzZUHNyk_m7VJWcGJJZyCgk78qVyhxL5g3hx0Vmb6-Xw7naU_o"
    />

    <NewsItem
      image="/um.png"
      title="Danmarkskanon - Rigsfællesskabet og Færøerne"
      blurb="Hvad sker der, når en kultur i et lille samfund møder den globaliserede verden? Hør, hvad Ragnhild Hjalmarsdóttir Højgaard mener, at Færøerne skal værne om i mødet med den globaliserede verden. I filmen lægger hun op til debat med sit bud på Færøerne og rigsfællesskabet."
      link="https://www.facebook.com/undervisningsministeriet/videos/1709206089147252/"
    />

    <NewsItem
      title="Góðan Morgun Føroyar"
      blurb="Tað eru so nógvir møguleikar við føroysku ullini, at vit ikki kunnu loyva okkum at blaka hana burtur, heldur Ragnhild Hjalmarsdóttir Højgaard"
      image="/kvf.png"
      link="https://kvf.fo/gmf?sid=72503&page=17"
    />

    <NewsItem
      title="in.fo | Føroysk ull á kendari altjóða designmessu"
      blurb="70 tons av ull eru væntandi endað í brenniovninum í ár. Alt hetta meðan gólvteppir framleidd úr føroyskari ull í hesum døgum verða sýnd fram á London Design Fair. Talan er um eina fráfaringarverkætlan frá Kunstakademinum í Keypmannahavn, ið hevur verið eitt dømi um at síggja menningarmøguleikarnar innan tilfeingi og skapa burðardygga framleiðslu"
      link="http://www.in.fo/news-detail/foeroysk-ull-a-kendari-altjoda-designmessu/"
      image="/info_logo.png"
    />
  </div>
);

export default News;

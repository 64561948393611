const glasir = {
  uri: 'glasir',
  title: 'GLASIR',
  subtitle: 'Artdecoration - a modern interpretation of tapestries',
  image: '/glasir/glasir.jpg',
  details: {
    LOCATION: 'Tórshavn, Faroe Islands',
    CLIENT: 'Mentamálaráðið; the Faroese Ministry of Culture; Landsverk',
    ARCHITECT: 'Bjarke Ingels Group',
    COLLABORATORS: 'Statens Værksteder for Kunst, TextielLab Tilburg, Barker, Sortimenti',
    TIMELINE: '2016-2018',
    SIZE: ' 200m², 150kg',
  },
  sections: [{
    text: 'This project is based on winning the art competition for decorating the Tórshavn College, Glasir. This artdecoration is situated in the two auditoriums, which are located between a large atrium and sportshall. The walls of these lecture halls are transparent, as the walls are made out of glass. This provides the opportunity to look into the sports hall and the atrium. The artdecoration makes it possible to create a closed space around the auditoriums. The artdecoration encompasses 7 large woven surfaces in faroese wool, which in fact cover walls from floor to ceiling. The surfaces are visible from inside auditoriums as well as from the outside areas surrounding it.',
  }, {
    image: '/glasir/5.png',
  }, {
    text: 'The surfaces depict a large pattern flow with references to nature. The textile can form the spaces of the auditoriums and shield against distractions and light from the outside. The curtains serve as a kind of embrace, a security and a comfortable environment to the user to immerse themselves in. The proposal is rooted in faroese tradition in the utilization of the faroese wool and simultaneously be linked up to the present in the use of digital technique and jaquard looms. The artecoration serves the rooms acoustically aswell. Each surface is composed as a unique picture, and the two auditoriums have the same identity in terms of pattern expression, but still have their own identity by havind different colourcombinations. The four colours of yarna used in this project, are the original natural colours of the wool, i.e. lightgrey-fairgrey and white-fairbrown.',
  }, {
    image: '/glasir/1.png',
  }, {
    image: '/glasir/2.png',
  }, {
    image: '/glasir/3.png',
  }, {
    image: '/glasir/4.png',
  }, {
    image: '/glasir/6.png',
  }, {
    image: '/glasir/7.png',
  }, {
    image: '/glasir/8.png',
  }, {
    image: '/glasir/9.png',
  }, {
    image: '/svfk_small.jpg',
  }],
};

const mal = {
  uri: 'mal',
  title: 'MÁL',
  subtitle: 'Textile sculpure, “Dimma og Tám”\n//\nMÁL summer exhibition, curated by Jón Sonni Jensen',
  image: '/mal/4.jpg',
  details: {
    LOCATION: 'National Gallery og the Faroe islands',
    TIMEFRAME: 'July 2018',
  },
  sections: [{
    text: `Two surfaces, depicting subtle woven gradients in natural coloued faroese wool. Extended from ceiling creating a floating space.
Dimma and Tám” is a faroese, and translates as “dusk and haze`,
  }, {
    imageBig: '/mal/mal.jpg',
  }, {
    imageBig: '/mal/3.jpg',
  }, {
    imageBig: '/mal/2.jpg',
  }, {
    imageBig: '/mal/5.jpg',
  }, {
    image: '/mal/1.jpg',
  }],
};

const noma = {
  uri: 'noma',
  title: 'NOMA',
  subtitle: 'Restaurant textile design',
  image: '/noma/8.jpg',
  details: {
    LOCATION: 'Copenhagen, Denmark',
    CLIENT: 'Noma',
    ARCHITECT: 'Bjarke Ingels Group',
    'INTERIOR DESIGN': 'David Thulstrup',
    COLLABORATORS: 'Statens Værksteder for Kunst',
    DELIVERY: 'February 2018',
  },
  sections: [{
    text: 'Bespoke woven cushion designs for the Noma lounge area. Made from Faroese wool and unique yarns.',
  }, {
    link: {
      image: '/noma/insta_3.png',
      url: 'https://www.instagram.com/p/Bk4vC7aHgmb',
    },
  }, {
    link: {
      image: '/noma/insta_1.png',
      url: 'https://www.instagram.com/p/Bf0G6dIDYsA',
    },
  }, {
    link: {
      image: '/noma/insta_2.png',
      url: 'https://www.instagram.com/p/Bk4vC7aHgmb',
    },
  }, {
    imageBig: '/noma/2.jpg',
  }, {
    imageBig: '/noma/3.jpg',
  }, {
    imageBig: '/noma/4.jpg',
  }, {
    imageBig: '/noma/5.jpg',
  }, {
    imageBig: '/noma/6.jpg',
  }, {
    imageBig: '/noma/7.jpg',
  }, {
    imageBig: '/noma/1.jpg',
  }, {
    image: '/svfk_small.jpg',
  }],
};

const ull = {
  uri: 'ull-er-foroya-gull',
  title: 'ULL ER FØROYA GULL',
  subtitle: 'Rug collection',
  image: '/ull/carpets.jpg',
  details: {
    EXHIBITIONS: 'Curated by KADK for the group-exhibition “LØSNINGER”, Copenhagen, july-august, London Design Week, september 2017 and UN-City Copenhagen, november 2017. Curated by Finderskeepers, for the group exhibition “SHOW THE WAY”, november 2018. Curated by trendforcasters for the exhibition “ONE”, Formland, febuary 2018.',
  },
  sections: [{
    text: 'The old faroese saying "Ull er Føroya Gull" (Wool is Faroe Gold) derrives from a pre-industrial time, where the Faroese wool was a highly valued natural resource which was carefully processed in the Faroese household. Unfortunately, today much of the wool ends up as a waste-product.',
  }, {
    text: 'This project is based on a study of the potential of revitalizing the Faroese wool as a valuable and sustainable resource, instead of ending up as waste. The potential of the wool has been investigated by focusing on an application based on the Faroese wool\'s specific functional and aesthetic properties. The natural wool colors are twisted and blended in tufted surfaces and woven designs that emphasize the wool\'s very special material character and fine shades.',
  }, {
    image: '/ull/exhibition.png',
  }, {
    text: 'The design process involved researching the inherent quality of the raw material aswell as understanding how production processes affect the yarn quality. The development of the carpets has explored the potential of the material in creating diverse tactile surfaces, resulting in an extensive catalogue of samples, that emphasizes the quality and the natural colors in a simple and subtle way.',
  }, {
    image: '/ull/tufted/tactility.jpg',
  }, {
    text: 'The project aims to pitch the material cultural value chain as a sustainable quality product with a distinctive history. This both goes into a vision for realistic development on wool production and business opportunities in the Faroe Islands and braids into a global UN target for sustainable consumption and production.',
  }, {
    image: '/ull/woven/rug_design.jpg',
  }, {
    imageA: '/ull/woven/form.jpg',
    imageB: '/ull/tufted/form.jpg',
  }, {
    imageBig: '/ull/woven/rug_pattern.jpg',
  }, {
    imageBig: '/ull/woven/runner_form.jpg',
  }, {
    imageBig: '/ull/woven/runner_tactility_pattern.jpg',
  }, {
    imageBig: '/ull/woven/rug_tactility.jpg',
  }, {
    imageA: '/ull/tufted/design.jpg',
    imageB: '/ull/tufted/tactility.jpg',
  }, {
    imageBig: '/ull/tufted/surface.jpg',
  }],
};

const yellow = {
  uri: 'meditation-on-yellow',
  title: 'MEDITATION ON YELLOW',
  subtitle: 'Bespoke tufted rug',
  image: '/yellow/3.png',
  details: {
    CLIENT: 'Private residential',
    DATE: 'May 2016',
  },
  sections: [{
    text: 'Concept and design developed from the client\'s favorite color - yellow.',
  }, {
    html: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/190721138?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
<p><a href="https://vimeo.com/190721138">Tufting - h&aring;ndlavet t&aelig;pper</a> from <a href="https://vimeo.com/user12000899">We Do Film</a> on <a href="https://vimeo.com">Vimeo</a>.</p>`,
  },
  ],
};


export default [glasir, mal, noma, ull, yellow];

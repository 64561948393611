const routes = [
  { name: 'main', path: '/' },
  { name: 'about', path: '/about' },
  { name: 'news', path: '/news' },
  {
    name: 'project',
    path: '/project/:uri',
  },
];

export default routes;

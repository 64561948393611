import createRouter from 'router5';
import browserPlugin from 'router5/plugins/browser';

import listenersPlugin from 'router5/plugins/listeners';
import routes from './routes';


export default function configureRouter() {
  const router = createRouter(routes, {
    defaultRoute: 'main',
    trailingSlash: true,
    strictQueryParams: true,
  });

  router.usePlugin(browserPlugin({ useHash: false }));
  router.usePlugin(listenersPlugin());

  return router;
}

import projects from './projects';

export default function projectsReducer(state = projects, action) {
  switch (action.type) {
    case 'PROJECTS/INIT':
      return action.state;
    default:
      return state;
  }
}

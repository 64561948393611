import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import projectShape from '../../redux/reducers/projects/shape';
import ProjectPreview from '../../components/ProjectPreview';

import './Main.css';

class Main extends PureComponent {
  static propTypes = {
    projects: PropTypes.arrayOf(projectShape).isRequired,
  };

  render() {
    const { projects } = this.props;
    return (
      <div className="projects">
        { projects.map(project => (
          <ProjectPreview
            key={project.uri}
            project={project}
          />
        )) }
      </div>
    );
  }
}

export default connect(state => ({ projects: state.projects }))(Main);

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router5';

import * as serviceWorker from './serviceWorker';

import './index.css';

import App from './containers/App';

import configureRouter from './router';
import configureStore from './redux';

const router = configureRouter();

const store = configureStore(router);

router.start(() => {
  ReactDOM.render((
    <Provider store={store}>
      <RouterProvider router={router}>
        <App />
      </RouterProvider>
    </Provider>
  ), document.getElementById('root'));
});

serviceWorker.unregister();

import { createStore, applyMiddleware } from 'redux';

import createReducer from './reducers';
import configureMiddleware from './middleware';

export default function configureStore(router) {
  const store = createStore(
    createReducer(),
    applyMiddleware(...configureMiddleware(router))
  );

  // Set store, makes it accessible in router5 middleware.
  router.setDependency('store', store);

  return store;
}
